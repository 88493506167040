import React, { useCallback, useContext } from 'react';

import {
  Typography,
  SPACING,
  UploadProps,
  FormItem,
  Select,
  TextArea,
  Dragger,
  Flex,
  FormInstance,
  useWatch,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import { Session } from '@optii/shared';
import { FILE_SIZE_LIMIT, useStoreFileMutation } from '@optii/global';
import { UploadOutlined } from '@ant-design/icons';
import { FilePreview } from './Upload';
import { FormContext } from './context';

type Props = {
  type: 'Housekeeping' | 'Service';
  form: FormInstance;
};

const getFile = (e: [] | { fileList: unknown }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
export function NotesAndAttachments({ type, form }: Props) {
  const { t } = useTranslation(['jobs', 'common']);
  const { globalSnack } = useContext(Session);
  const { checklists, shard } = useContext(FormContext);

  const attachments = useWatch('attachments', form);

  const [storeFile] = useStoreFileMutation({
    onError(error) {
      console.error(error);
      return globalSnack({
        message: t('jobs:Could not upload attachment'),
        timeout: 5000,
        error: true,
      });
    },
    context: {
      _shard: shard,
    },
  });
  const uploadFile = useCallback(
    async (file: Blob) => {
      if (file.size >= FILE_SIZE_LIMIT)
        return globalSnack({
          message: t(
            'common:This file is too large please provide a file under 20MB',
          ),
          error: true,
          timeout: 5000,
        });

      const response = await storeFile({
        variables: {
          file,
          name: file.name,
        },
      });

      return {
        uid: response.data?.file.UUID,
        url: response.data?.file.URL,
        src: response.data?.file.URL,
        name: response.data?.file.name,
      };
    },
    [globalSnack, storeFile, t],
  );

  const customRequest: UploadProps['customRequest'] = async (options) => {
    const { file, onSuccess } = options;

    if (typeof onSuccess === 'function') {
      const response = await uploadFile(file as Blob);
      onSuccess(response);
    }
  };

  return (
    <>
      <Typography.Title
        level={4}
        style={{
          marginBottom: SPACING.SIZE_MD,
          fontFamily: 'Montserrat',
        }}
      >
        {t('jobs:Notes and Attachments')}
      </Typography.Title>

      <FormItem
        name="checklistTemplates"
        label={t('common:Checklist')}
        normalize={(value) => [
          {
            id: value,
          },
        ]}
        getValueProps={(valueProps) => ({
          value: type === 'Housekeeping' ? valueProps : valueProps?.[0]?.id,
        })}
      >
        <Select
          placeholder={t('common:Select...')}
          allowClear
          mode={type === 'Housekeeping' ? 'multiple' : undefined}
          options={checklists.options}
          loading={checklists.loading}
          optionFilterProp="label"
        />
      </FormItem>

      <FormItem name="note" label={t('common:Notes')}>
        <TextArea minLength={2} />
      </FormItem>

      <FormItem
        name="attachments"
        label={t('common:Attachments')}
        valuePropName="fileList"
        getValueFromEvent={getFile}
        normalize={(value) => {
          if (Array.isArray(value)) {
            return value.map((item) => item?.response || item);
          }
          return value;
        }}
      >
        <Dragger multiple showUploadList={false} customRequest={customRequest}>
          <Flex align="center" gap={SPACING.SIZE_SM}>
            <UploadOutlined />
            <Typography.Text
              style={{
                fontWeight: 500,
              }}
            >
              {t('jobs:Click or drag a file to this area to upload')}
            </Typography.Text>
          </Flex>
        </Dragger>
      </FormItem>

      <FilePreview form={form} fileList={attachments || []} />
    </>
  );
}

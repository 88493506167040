import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  FileTextOutlined,
  LoadingOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import {
  generateBase64ImageFromVideo,
  isFileExtensionImageOrVideo,
  ToolbarRender,
} from '@optii/global';
import {
  Typography,
  Image,
  COLORS,
  ConfigProvider,
  Flex,
  SPACING,
  Space,
  RADIUS,
  Divider,
  Button,
  FormInstance,
} from '@optii/ui-library';
import i18next from 'i18next';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { CSSProperties } from 'styled-components';

type CustomFileItemProps = {
  url: string;
  name: string;
  onDownload: () => void;
  onPreview: () => void;
  onRemoveFile: () => void;
};

const ICON_COLOR_STYLE: CSSProperties = {
  color: '#BFBFBFBF',
};

export function CustomFileItem({
  name,
  onDownload,
  onPreview,
  onRemoveFile,
  url,
}: CustomFileItemProps) {
  const [videoThumbnail, setVideoThumbnail] = useState('');
  const { isVideo, isImage } = isFileExtensionImageOrVideo(name);

  const isVideoOrImage = isVideo || isImage;

  useEffect(() => {
    (async () => {
      const response = await generateBase64ImageFromVideo(url);

      setVideoThumbnail(response);
    })();
  }, [isVideo, url]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            colorText: COLORS.neutral[8],
          },
          Divider: {
            verticalMarginInline: SPACING.NONE,
          },
          Button: {
            paddingInlineLG: SPACING.NONE,

            paddingBlock: SPACING.NONE,
          },
        },
      }}
    >
      <Flex
        align="center"
        vertical={false}
        style={{
          border: `1px solid ${COLORS.neutral[5]}`,
          borderRadius: RADIUS.RADIUS_DEFAULT,
        }}
      >
        {isVideoOrImage ? (
          <>
            <Image
              placeholder={
                <Flex align="center" justify="center">
                  <LoadingOutlined loop />
                </Flex>
              }
              preview={{
                mask: <EyeOutlined />,
              }}
              onClick={(e) => {
                e.stopPropagation();
                onPreview();
              }}
              src={isVideo ? videoThumbnail : url}
              width={40}
              height={40}
            />
            <Divider
              type="vertical"
              style={{
                height: '100%',
              }}
            />
          </>
        ) : (
          <>
            <FileTextOutlined
              style={{
                marginInline: SPACING.SIZE_DEFAULT,
                ...ICON_COLOR_STYLE,
              }}
            />

            <Button type="link" onClick={onDownload}>
              <Typography.Text
                underline
                style={{
                  maxWidth: 100,
                }}
                ellipsis={{
                  tooltip: name,
                }}
              >
                {name}
              </Typography.Text>
            </Button>
          </>
        )}

        <Button
          type="link"
          icon={<DeleteOutlined />}
          danger
          onClick={(e) => {
            e.stopPropagation();
            onRemoveFile();
          }}
          theme={{
            components: {
              Button: {
                primaryColor: COLORS.red[8],
              },
            },
          }}
        />
      </Flex>
    </ConfigProvider>
  );
}

type FileList = {
  uid: string;
  url: string;
  src: string;

  name: string;
};

const { PreviewGroup } = Image;

export const toolbarRender = (
  info: ToolbarRender['info'],
  fileList: FileList[],
  onDownload: (current: number) => void,
  isMobile?: boolean,
): ReactNode => {
  const {
    transform: { scale },
    actions: {
      onFlipX,
      onFlipY,
      onRotateLeft,
      onRotateRight,
      onZoomIn,
      onZoomOut,
      onReset,
    },
    current,
  } = info;

  const isCurrentFileVideo = isFileExtensionImageOrVideo(
    fileList[current].name,
  ).isVideo;

  return (
    <Space
      size={SPACING.SIZE_MD}
      className="toolbar-wrapper"
      style={{
        paddingInline: isMobile ? SPACING.NONE : SPACING.SIZE_XL,
        borderRadius: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      }}
      styles={{
        item: {
          padding: SPACING.SIZE_MS,
          fontSize: 21,
        },
      }}
    >
      <DownloadOutlined onClick={() => onDownload(current)} />
      {isCurrentFileVideo ? null : (
        <>
          <SwapOutlined rotate={90} onClick={onFlipY} />
          <SwapOutlined onClick={onFlipX} />
          <RotateLeftOutlined onClick={onRotateLeft} />
          <RotateRightOutlined onClick={onRotateRight} />
          <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
          <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          <UndoOutlined onClick={onReset} />
        </>
      )}
    </Space>
  );
};

type Props = {
  fileList: FileList[];
  form: FormInstance;
};
export function FilePreview({ fileList, form }: Props) {
  const [currentAttachment, setCurrentAttachment] = useState(0);
  const onDownload = (current: number) => {
    const { url, name } = fileList[current];
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  function imageRender(originalNode: ReactElement, info: unknown) {
    const { current } = info as { current: number };

    if (!fileList.length && !current) return originalNode;

    const { name, url } = fileList[current];

    const { isVideo, isImage } = isFileExtensionImageOrVideo(name);

    if (isVideo) {
      return (
        <video width="75%" controls src={url}>
          <track kind="captions" />
        </video>
      );
    }

    if (isImage) return originalNode;

    const desc = i18next.t(
      "common:There's no preview available for this file",
    ) as string;
    return (
      <Typography.Text
        style={{
          color: COLORS.neutral[1],
        }}
      >
        {desc}
      </Typography.Text>
    );
  }

  return (
    <Flex gap={SPACING.SIZE_SM} wrap="wrap">
      <ConfigProvider
        theme={{
          components: {
            Typography: {
              colorText: COLORS.neutral[8],
            },
            Divider: {
              verticalMarginInline: SPACING.NONE,
            },
          },
        }}
      >
        <PreviewGroup
          items={fileList}
          preview={{
            imageRender,
            current: currentAttachment,
            onChange: (current: number) => {
              setCurrentAttachment(current);
            },
            toolbarRender(originalNode, info) {
              return toolbarRender(info, fileList, onDownload);
            },
          }}
        >
          {fileList?.map(({ uid, url, name }, index) => (
            <CustomFileItem
              key={uid}
              url={url}
              name={name}
              onDownload={() => onDownload(index)}
              onPreview={() => setCurrentAttachment(index)}
              onRemoveFile={() => {
                const list = fileList.filter((file) => file.uid !== uid);

                form.setFieldValue('attachments', list);
              }}
            />
          ))}
        </PreviewGroup>
      </ConfigProvider>
    </Flex>
  );
}

import { PERMISSIONS, useAccess, UserAccessContext } from '@optii/shared/index';
import {
  Button,
  COLORS,
  ConfigProvider,
  Flex,
  Input,
  Modal,
  RADIUS,
  SPACING,
  ThemeConfig,
  Typography,
} from '@optii/ui-library';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SendOutlined } from '@ant-design/icons';
import { Conversation, JSONValue } from '@twilio/conversations';
import { generateAttributes, sendMessage } from '../../../utils/message';
import { debounce } from '../../../utils/debounce';
import { FileList, TaggedUser } from '../../../types';
import { ChatContext } from '../../../context/chat.context';
import { SelectOption } from '../../message/types';
import { SelectEmployees } from './SelectEmployees';
import { InputFilePreview } from '../../message/InputFilePreview';
import { UploadFile } from '../../message/UploadFIle';
import MessageField from '../../message/MessageField';
import { EmployeeStatus } from '../../../api/data';

const MENTIONS_THEME: ThemeConfig = {
  components: {
    Input: {
      borderRadiusLG: RADIUS.RADIUS_L,
      sizeXL: 40,
    },
    Mentions: {
      paddingInline: 16,
      paddingBlock: 12,
    },
    Button: {
      colorPrimary: COLORS.secondary[5],
      colorPrimaryActive: COLORS.secondary[6],
      colorPrimaryHover: COLORS.secondary[6],
    },
  },
};

type CreateChannelProps = {
  open: boolean;
  onClose: () => void;
};

export function CreateChannel({ open, onClose }: CreateChannelProps) {
  const { t } = useTranslation(['chat', 'common']);
  const { user } = useContext(UserAccessContext.Context);
  const { client, employees, channels, chatEmployeeIds } =
    useContext(ChatContext);
  const { can } = useAccess();
  const [search, setSearch] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [fileList, setFileList] = useState<FileList[]>([]);
  const [taggedUsers, setTaggedUsers] = useState<TaggedUser[]>([]);
  const [message, setMessage] = useState<string | undefined>('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<'error' | ''>('');

  const CAN_CREATE_DM_GROUPS = can(PERMISSIONS.communications.dynamicGroups);
  const CAN_ACCESS_ALL_TEAM_MEMBERS = can(
    PERMISSIONS.communications.accessAllTeamMembers,
  );

  const { privateChannels } = channels;
  const history = useHistory();

  function filterTaggedUsers(input: string) {
    setTaggedUsers((prev) =>
      prev.filter((label) => {
        const userLabelMention = `@${label}`;

        return input.includes(userLabelMention);
      }),
    );
  }

  function onCloseModal() {
    setSelectedEmployees([]);
    setFileList([]);
    setSearch('');
    onClose();
  }

  const filterTeamMembers = useCallback(
    (employee: SelectOption) =>
      search
        ? employee.label
            .toLowerCase()
            .trim()
            .indexOf(search.toLowerCase().trim()) !== -1
        : true,
    [search],
  );

  const availableEmployees = useMemo(
    () =>
      (CAN_CREATE_DM_GROUPS
        ? employees
        : employees.filter(
            (employee) =>
              !privateChannels
                .map((privateChannel) => privateChannel.label)
                .includes(employee.label),
          )
      )
        .filter((employee) => employee.value !== user?.id)
        .filter(filterTeamMembers)
        .filter(({ value }) =>
          CAN_ACCESS_ALL_TEAM_MEMBERS ? true : chatEmployeeIds?.includes(value),
        )
        .filter(
          ({ status: employeeStatus }) =>
            employeeStatus === EmployeeStatus.Active,
        )
        .sort((a, b) => a.label.localeCompare(b.label)),
    [
      CAN_CREATE_DM_GROUPS,
      CAN_ACCESS_ALL_TEAM_MEMBERS,
      employees,
      privateChannels,
      user?.id,
      filterTeamMembers,
      chatEmployeeIds,
    ],
  );

  function onSearchTeamMembers(e: ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  const debouncedSearchTeamMembers = debounce<
    React.ChangeEvent<HTMLInputElement>
  >(onSearchTeamMembers, 500);

  const mentionOptions = useMemo(
    () =>
      [
        {
          value: t('chat:Channel'),
          label: t('chat:Channel'),
          key: 'channel',
        },
      ]
        .concat(
          employees.map((employee) => ({
            value: employee.label,
            label: employee.label,
            key: employee.value,
          })),
        )
        .filter((employee) => selectedEmployees.includes(employee.value)),
    [t, employees, selectedEmployees],
  );

  const onSendMessage = async (channel: Conversation) => {
    const attributes = generateAttributes(
      fileList,
      true,
      channel,
      user,
    ) as JSONValue;

    await sendMessage(setMessage, taggedUsers, t, attributes, channel, message);
  };

  const onCreateChannel = async () => {
    if (!client || !message) return undefined;

    setLoading(true);
    const { identity } = client.user;
    const newChannelName = selectedEmployees.concat([identity]).join('_');

    return client
      .createConversation({
        friendlyName: newChannelName,
        uniqueName: newChannelName,
      })
      .then(async (newConversation) => {
        await newConversation.join();

        filterTaggedUsers(message);

        await Promise.all(
          selectedEmployees.map(async (employee) =>
            newConversation.add(employee),
          ),
        );

        await newConversation.setAllMessagesRead();
        await onSendMessage(newConversation).finally(() => {
          history.push(newConversation.sid);
        });
      })
      .catch(async (error) => {
        if (error.status === 409) {
          console.error('Channel already exists, redirecting user to Channel.');

          const existingConversation =
            await client.getConversationByUniqueName(newChannelName);

          await onSendMessage(existingConversation).finally(() =>
            history.push(existingConversation.sid),
          );
        }
      })
      .finally(() => {
        setLoading(false);
        onCloseModal();
      });
  };

  const disabled = !selectedEmployees.length;

  return (
    <Modal
      title={
        !selectedEmployees.length
          ? t('chat:Select Team Member(s)')
          : employees
              .filter((availableEmployee) =>
                selectedEmployees.includes(availableEmployee.value),
              )
              .map((employee) => employee.label)
              .join(', ')
      }
      open={open}
      footer={null}
      destroyOnClose
      onCancel={onCloseModal}
      onClose={onCloseModal}
    >
      <Input
        placeholder={t('chat:Search Team Member(s)')}
        onChange={debouncedSearchTeamMembers}
        data-testid="search-team-members"
        style={{
          marginBottom: SPACING.SIZE_MD,
        }}
      />

      <SelectEmployees
        client={client}
        canSelectMultiple={CAN_CREATE_DM_GROUPS}
        options={availableEmployees}
        setSelectedEmployees={setSelectedEmployees}
        selectedEmployees={selectedEmployees}
      />

      <ConfigProvider theme={MENTIONS_THEME}>
        <InputFilePreview fileList={fileList} setFileList={setFileList} />
        {status === 'error' ? (
          <Typography.Text
            type="danger"
            style={{
              display: 'flex',
              marginLeft: 48,
            }}
          >
            Character limit exceeded
          </Typography.Text>
        ) : null}
        <Flex
          gap={SPACING.SIZE_DEFAULT}
          style={{
            position: 'relative',
          }}
          align="center"
        >
          <UploadFile
            setFileList={setFileList}
            disabled={disabled || status === 'error'}
          />

          <MessageField
            placeholder={t('chat:Add new message')}
            options={mentionOptions}
            value={message}
            setStatus={setStatus}
            status={status}
            key="key"
            prefix={selectedEmployees.length > 1 ? '@' : 'ªº'}
            styles={{
              textarea: {
                paddingRight: SPACING.SIZE_XXXL,
              },
            }}
            autoSize={{
              minRows: 1,
              maxRows: 6,
            }}
            disabled={disabled}
            onSelect={(option) => taggedUsers.push(option as TaggedUser)}
            setTaggedUsers={setTaggedUsers}
            setValue={setMessage}
            onSendMessage={onCreateChannel}
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={onCreateChannel}
            loading={loading}
            disabled={loading || disabled || status === 'error'}
            style={{
              position: 'absolute',
              right: SPACING.NONE,
              top: SPACING.NONE,
              transform: 'translate(-8px, 8px)',
            }}
          />
        </Flex>
      </ConfigProvider>
    </Modal>
  );
}

import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  REMOVE_ADHOC_JOB_NOTE,
  UPDATE_ADHOC_JOB_NOTE,
  UPDATE_REPEATING_JOB,
} from 'queries';
import {
  Session,
  JobDetailsContext,
  ViewJobDetailsContext,
  DetailsContext,
} from '@optii/shared';
import { useTranslation } from 'react-i18next';
import { useQueryParams, BooleanParam, StringParam } from 'use-query-params';
import { translatedData } from 'utils/formatters/jobs';
import { JOB_STATUS } from 'utils/constants';
import { formatUpdateRepeatingJobInput } from 'utils/formatters/repeatingJobs';
import { omitDeep } from '@apollo/client/utilities';

function formatRepeatingJob({ job, asset, note, id }) {
  const { priorities, jobStatusOptions } = translatedData;

  const { jobTemplate } = job;

  const jobNote = jobTemplate.notes.find((_note) => _note.id === id);
  const newNote = note && jobNote ? { ...jobNote, text: note } : undefined;

  const notes = jobTemplate.notes.filter((_note) => _note.id !== id);
  if (newNote) notes.push(newNote);

  const input = {};

  input.items = jobTemplate.items;

  return formatUpdateRepeatingJobInput(
    {
      id: job.id,
      version: job.version,
      status: 'active',
      cycle: {
        displayName: job.cycle.displayName,
        frequency: job.cycle.frequency,
        type: job.cycle.type,
        value: job.cycle.value,
      },
      startDate: job.startDate,
      endDate: job?.endDate,
      jobTemplate: {
        items: jobTemplate.items,
        type: jobTemplate.type,
        action: jobTemplate.action,
        priority: priorities.find(
          (priority) => priority.id === jobTemplate.priority,
        ),
        dueByTime: jobTemplate?.dueByTime,
        status: {
          id:
            jobTemplate.status === JOB_STATUS.new
              ? jobTemplate.status
              : jobStatusOptions.find(
                  (status) => status.id === jobTemplate.status,
                ).id,
        },
        attachments: jobTemplate.attachments || [],
        department: jobTemplate.department,
        roles: jobTemplate.role && {
          ...jobTemplate.role,
          displayName: jobTemplate.role.name,
        },
        assignee: jobTemplate.assignee,
        notes,
        location: jobTemplate.locations.map((prev) => ({
          ...prev,
          id: prev.id,
        })),
      },
    },
    false,
    input,
  );
}

/**
 * Hook for updating job notes
 * @returns {UseJobNoteEditorReturn}
 */

export function useJobNoteEditor(props) {
  const { t } = useTranslation(['common', 'fields']);
  const [edit, setEdit] = useState(false);
  const { globalSnack } = useContext(Session);
  const { refetch, isRepeatingJobTemplate } = useContext(ViewJobDetailsContext);
  const { refetch: refetchEditJob } = useContext(JobDetailsContext);
  const [query] = useQueryParams({ edit: BooleanParam, view: StringParam });
  const {
    asset,
    job,
    refetch: refetchRepeatingJobs,
  } = useContext(DetailsContext);
  const isRepeatingJob = isRepeatingJobTemplate || query.view === 'repeating';

  const refetchJob = () => {
    if (isRepeatingJob) {
      refetchRepeatingJobs();
    } else if (query.edit) {
      refetchEditJob();
    } else {
      refetch();
    }
  };

  const [update, { loading: editLoading }] = useMutation(
    isRepeatingJob ? UPDATE_REPEATING_JOB : UPDATE_ADHOC_JOB_NOTE,
    {
      context: { _instance: 'node', _shard: props.shard },
      onError: () => {
        globalSnack({
          message: t('common: Something went wrong trying to edit the note'),
          error: true,
        });
      },
      onCompleted: (_) => {
        globalSnack({
          message: t('jobs: Note has been updated'),
        });
        refetchJob();
        setEdit(false);
      },
    },
  );

  const [remove, { loading: removeLoading }] = useMutation(
    REMOVE_ADHOC_JOB_NOTE,
    {
      context: { _instance: 'node', _shard: props.shard },
      onError: () => {
        globalSnack({
          message: t('common: Something went wrong trying to remove the note'),
          error: true,
        });
      },
      onCompleted: (_) => {
        globalSnack({
          message: t(`jobs: Note has been deleted`),
        });
        refetchJob();
      },
    },
  );

  async function handleUpdate({ note, id }) {
    if (isRepeatingJob) {
      const updateRepeatingJobInput = omitDeep(
        formatRepeatingJob({
          job: job || props.job,
          asset,
          note,
          id,
        }),
        '__typename',
      );

      await update({
        variables: {
          updateRepeatingJobInput,
        },
        context: {
          _shard: props.shard,
          _instance: 'node',
        },
      });
    } else {
      await update({
        variables: {
          adhocJobNoteInput: {
            note,
          },
          adhocJobNoteId: id,
        },
        context: {
          _shard: props.shard,
          _instance: 'node',
        },
      });
    }
  }

  async function handleRemove({ id }) {
    isRepeatingJob
      ? await handleUpdate({ id })
      : await remove({
          variables: {
            adhocJobNoteId: id,
          },
        });
  }

  return {
    update: handleUpdate,
    remove: handleRemove,
    editLoading,
    removeLoading,
    edit,
    setEdit,
  };
}
